.footer {
  background-color: #fff;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.footerInner {
  padding: 70px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.footerBlock {
  width: 33.33333%;
  padding: 0 15px;

  text-align: center;
}

.footerContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* .footerLogo {
  background: #000;
  color: #000;
} */

.nav {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
}

.navLink {
  color: rgba(0, 4, 15, 0.8);
  font-weight: bold;
  text-transform: uppercase;
  font-size: 18px;
  opacity: 1;
  position: relative;
  margin: 0px 0px 15px 0px;
  transition: opacity 0.2s linear;
}

.navLink:hover {
  opacity: 0.75;
  cursor: pointer;
}

.navLink:last-child {
  margin: 0px;
}

.contactInfo {
  display: flex;
  flex-direction: column;
}

.contactInfo a {
  color: rgba(0, 4, 15, 0.8);
  display: inline-flex;
  align-items: center;
  text-align: left;
}

.adress {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.phone {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.email {
  display: flex;
  align-items: center;
}

.icon {
  font-size: 26px;
  margin-right: 8px;
  color: rgba(0, 4, 15, 0.8);
}

.copyright {
  background-color: rgba(0, 4, 15, 0.8);
}

.copyrightText {
  padding: 15px 0px;
  text-align: center;
  font-size: 14px;
  color: #fff;
}

@media (max-width: 705px) {
  .footerInner {
    padding: 40px 0;
  }

  .footerBlock {
    width: 100%;
  }

  .footerContent {
    padding: 0px 0px 30px 0px;
  }

  .contactInfo {
    align-items: center;
  }

  .contactInfo a {
    text-align: center;
  }
}
