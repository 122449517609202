.container {
  width: 100%;
  max-width: 850px;
  margin: 0 auto;
}

.about {
  padding: 0px 0px 0px 0px;
  background-color: rgba(0, 4, 15, 0.8);
  position: relative;
}

.aboutTitle {
  color: #fff;
  font-size: 30px;
  margin: 0px 0px 30px 0px;
  font-weight: bold;
}

.photo {
  text-align: center;
  padding: 0px 40px;
}

.photo img {
  max-width: 100%;
  object-fit: cover;
  margin: 0px 0px 30px 0px;
  border: 5px solid #ffffff;
  border-radius: 20px;
}

.aboutInfo p {
  line-height: 2;
}

.aboutInfo p:last-child {
  margin: 0px;
}

.aboutTitleInfo {
  color: #fff;
  font-size: 24px;
  font-weight: bold;
}

.aboutInfo p {
  color: #ffffff;
  font-size: 18px;
  margin: 0px 0px 0px 0px;
}

.icon {
  margin-top: 30px;
  display: flex;
  text-align: center;
}

.iconOne {
  width: 50%;
  margin: 0px 10px 0px 0px;
}

.iconTwo {
  width: 50%;
  margin: 0px 0px 0px 10px;
}

.iconInfo {
  display: block;
  background-color: rgba(255, 255, 255, 0.04);
  border-radius: 20px;
  color: #c0bdbd;
  text-transform: uppercase;
  font-size: 16px;
  padding: 10px;
  margin: 5px 0px 0px 0px;
  line-height: 1.6;
}

ul.icon li {
  font-size: 30px;
}

ul.icon li:last-child {
  margin-right: 0px;
}

.icon a:link {
  color: #fff;
}

.icon a:visited {
  color: rgb(255, 255, 255);
}

.icon a:hover {
  color: #c0bdbd;
}

.icon a:active {
  color: rgb(0, 0, 0, 0.5);
}

.modelClose {
  position: sticky;
  top: 0%;
  left: 100%;
  z-index: 1000;
  color: #fff;
  font-size: 40px;
  cursor: pointer;
}

.modelClose:active {
  color: rgba(255, 255, 255, 0.5);
}

@media (max-width: 768px) {
  .photo {
    padding: 0px 20px;
  }
}

@media (max-width: 425px) {
  .about {
    padding: 30px 0px 0px 0px;
  }

  .photo {
    padding: 0px;
  }
}

@media (max-width: 425px) {
  .aboutTitle {
    margin: 0px 0px 30px 0px;
  }
}

@media (max-width: 375px) {
  .aboutInfo p {
    font-size: 16px;
  }
}
