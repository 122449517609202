.services {
  margin: 0px;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.servicesTitle {
  color: rgba(0, 4, 15, 0.8);
  font-weight: bold;
  text-transform: uppercase;
  font-size: 20px;
  text-align: center;
  display: inline-block;
  margin: 50px 0px 15px 0px;
}

@media (max-width: 768px) {
  .servicesTitle {
    margin: 30px 0px 15px 0px;
  }
}
