.map {
  background: rgba(0, 4, 15, 0.8);
  margin: 50px 0px 0px 0px;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.mapInner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 500px;
}

.mapContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 30px 0px 0px;
}

.mapTitle {
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 20px;
  display: inline-block;
  margin: 0px 0px 15px 0px;
}

.contactInfo {
  display: flex;
  flex-direction: column;
}

.contactInfo a {
  color: #fff;
  display: inline-flex;
  align-items: center;
}

.adress {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.phone {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.email {
  display: flex;
  align-items: center;
}

.icon {
  font-size: 26px;
  margin-right: 8px;
  color: #fff;
}

iframe {
  width: 100%;
  height: 400px;
}

@media (max-width: 768px) {
  .mapInner {
    flex-direction: column;
  }

  .mapContent {
    padding: 30px 0px;
  }

  .mapTitle {
    text-align: center;
  }

  iframe {
    padding: 0px 0px 30px 0px;
  }
}
