.verticalBody {
  max-width: 1200px;
}

.verticalRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0px -25px;
}

.verticalColumn {
  margin: 0px 0px 0px 0px;
  padding: 0px 25px;
  flex: 0 1 50%;
  display: flex;
}

.verticalColumn:nth-last-of-type(1) {
  margin: 0px 0px 0px 0px;
}

@media (max-width: 768px) {
  .verticalRow {
    margin: 0px;
  }

  .verticalColumn {
    padding: 0px 0px 30px 0px;
    flex: 0 1 100%;
  }

  .verticalColumn:nth-last-of-type(1) {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
}

@media (max-width: 375px) {
/*   .verticalColumn {
    margin: 0px 0px 40px 0px;
  } */
}

.verticalItem {
  background-color: rgba(0, 4, 15, 0.8);
  color: #fff;
  border-radius: 20px;
  padding: 0 0 20px 0;
  display: flex;
  flex-direction: column;
}

.verticalSubtitle {
  font-size: 18px;
  text-transform: uppercase;
  padding: 12px 0px;
  text-align: center;
}

.verticalLabel img {
  max-width: 100%;
  object-fit: cover;
  padding: 0px 20px;
  border-radius: 60px;
}

.verticalText {
  padding: 20px;
  font-size: 16px;
  line-height: 28px;
  flex: 1 1 auto;
}

.verticalBtn {
  display: block;
  background-color: #fff;
  color: #000;
  margin: 0 20px;
  height: 40px;
  line-height: 40px;
  border-radius: 10px;
  text-align: center;
  font-size: 18px;
}

.verticalBtn:hover {
  background-color: #000;
  color: #fff;
  transition: all 0.8s;
}
