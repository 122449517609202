.about {
  margin: 0px 0px;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.aboutInner {
  display: flex;
  justify-content: space-between;
/*   align-items: center; */
  margin: 50px 0px 0px 0px;
}

.aboutContent {
  padding: 0px 50px 0px 0px;
}

.aboutImage {
  max-width: 100%;
  object-fit: cover;
}

.aboutTitle {
  color: rgba(0, 4, 15, 0.8);
  font-weight: bold;
  text-transform: uppercase;
  font-size: 20px;
  display: inline-block;
  margin: 50px 0px 15px 0px;
}

.aboutInfo {
  color: rgba(0, 4, 15, 0.8);
  font-size: 16px;
}

.aboutInfo p {
  padding-bottom: 15px;
}

.aboutInfo p:last-child {
  padding-bottom: 0px;
}

.documents a:link {
  color: rgba(0, 4, 15, 0.8);
}

.documents a:visited {
  color: rgb(0, 0, 0);
}

.documents a:hover {
  color: rgba(0, 4, 15, 0.4);
}

.documents a:active {
  color: rgb(0, 0, 0);
}

.aboutPrivacyPolicy {
  color: rgba(0, 4, 15, 0.8);
  font-size: 16px;
  text-decoration: underline;
  margin: 0px 0px 15px 0px;
}

.aboutOferta {
  color: rgba(0, 4, 15, 0.8);
  font-size: 16px;
  text-decoration: underline;

}

.aboutIicense {
  color: rgba(0, 4, 15, 0.8);
  font-size: 16px;
  text-decoration: underline;
  margin: 15px 0px 0px 0px;
}

@media (max-width: 1033px) {
  .aboutContent {
    padding: 0px;
  }

  .aboutInner {
    flex-direction: column;
    margin: 0px;
  }

  .aboutImage {
    padding: 30px 0px 0px 0px;
  }
}

@media (max-width: 768px) {
  .aboutTitle {
    margin: 30px 0px 15px 0px;
  }
}
