.slider {
  position: relative;
  height: 650px;
  overflow: hidden;
}

.slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  z-index: -10;
}

.currentSlide {
  opacity: 1;
}

.caption {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  color: rgba(0, 4, 15, 0.8);
  font-size: 36px;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.slideButtons {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.slideButtons button {
  padding: 10px;
  font-size: 35px;
  cursor: pointer;
  background: none;
  border: none;
  color: white;
}

.slideButtons .prevButton {
  margin-left: 10px;
}

.slideButtons .nextButton {
  margin-right: 10px;
}

.slideIndicators {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
}

.slideIndicators .indicator {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.3);
  margin: 0 5px;
}

.slideIndicators .indicator.active {
  background-color: rgba(0, 0, 0, 0.8);
}

@media (max-width: 768px) {
  .slider {
    height: 400px;
  }

  .caption {
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  .slider {
    height: 300px;
  }

  .caption {
    font-size: 16px;
  }
}

@media (max-width: 425px) {
  .slideButtons button {
    font-size: 25px;
  }

  .slideIndicators .indicator {
    width: 10px;
    height: 10px;
  }
}
